import './App.css';
import ChatPage from './chat/ChatPage.tsx';
import HomePage from "./home/HomePage.tsx"
import { Route, Navigate, Link, Outlet, useLocation, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import ProfilePage from './me/ProfilePage.tsx';
import LoginPage from './LoginPage.tsx'
import RegisterPage from './RegisterPage.tsx'
import WriterPage from './writing/WriterPage.tsx';
import { Toaster } from "react-hot-toast"
import CharacterCreator from './character/CharacterCreationPage.tsx';
import WriterCharactersPage from './writing/WriterCharactersPage.tsx';
import React, { useEffect, useState } from 'react';
import { CharacterProvider } from './context/CharacterContext.tsx';
import { UserProvider, useUser } from './context/UserContext.tsx';
import CharacterPage from './character/CharacterPage.tsx';
import BroadcastCreatePage from './broadcast/BroadcastCreatePage.tsx';
import BroadcastTopicPage from './broadcast/BroadcastTopicPage.tsx';
import CharacterChats from './character/CharacterChats.tsx';
import ChatHistoryPage from './chat/ChatHistoryPage.tsx';
import SettingPage from './SettingPage.tsx';
import CoinPage from './me/CoinPage.tsx';
import PaymentPage from './me/PaymentPage.tsx';
import WithdrawPage from './me/WithdrawPage.tsx';
import NotFoundPage from './NotFoundPage.tsx';
import DashboardPage from './admin/DashboardPage.tsx';
import CreateContentPage from './content/CreateContentPage.tsx';
import ChatSharePage from './chat/ChatSharePage.tsx';
import ContentPage from './content/ContentPage.tsx';
import ContentListPage from './content/ContentListPage.tsx';
import SearchPage from './SearchPage.tsx';
import CharacterSettingPage from './character/CharacterSettingPage.tsx';
import GroupChatCreatePage from './groupchat/GroupChatCreatePage.tsx';
import GroupChatPage from './groupchat/GroupChatPage.tsx';
import PublicProfilePage from './me/PublicProfilePage.tsx';
import ContentModPage from './content/ContentModPage.tsx';
import CharacterListPage from './character/CharacterListPage.tsx';
import ContentWizard from './content/ContentWizard.tsx';
import NotificationPage from './notification/NotificationPage.tsx';
import ChatBackgroundSelection from './chat/ChatBackgroundSelection.tsx';
import CharacterComments from './character/CharacterComments.tsx';
import CreateImageTextContentPage from './content/CreateImageTextContentPage.tsx';
import TextImageContentPage from './content/TextImageContentPage.tsx';
import EmailVerificationPage from './EmailVerificationPage.tsx';
import ForgetPasswordPage from './ForgetPasswordPage.tsx';
import ResetPasswordPage from './ResetPasswordPage.tsx';

function AppContainer() {

  return (
    <>
      <Outlet />
      <Toaster />
    </>
  )
}

function NavbarContainer() {

  const location = useLocation(); // Gets the current location

  // Function to check if the path matches the current location
  const isActive = (path) => location.pathname === path;

  return (
    <div className="drawer md:drawer-open">
    <input type="checkbox" className="drawer-toggle" />
    <div className="drawer-content flex flex-col bg-gradient-to-t from-grad-100 via-grad-200 to-grad-300 bg-fixed min-h-screen">
      <Outlet />
    </div>
    <div className="btm-nav md:hidden z-[999]">
        <Link to="/" className={isActive('/') ? 'active' : ''}>
          <div className="btm-nav-label">主页</div>
        </Link>
        <Link to="/share" className={isActive('/share') ? 'active' : ''}>
          <div className="btm-nav-label">社区</div>
        </Link>
        <Link to="/character/create/0" className="">
          <div className="btm-nav-label btn btn-sm btn-circle btn-accent text-lg">+</div>
        </Link>
        <Link to="/chat" className={isActive('/chat') ? 'active' : ''}>
          <div className="btm-nav-label">聊天</div>
        </Link>
        <Link to="/me" className={isActive('/me') ? 'active' : ''}>
          <div className="btm-nav-label">我的</div>
        </Link>
      </div>
      <div className="drawer-side z-[999]">
        <ul className="menu p-4 w-48 min-h-full bg-slate-200">
          {/* Sidebar content here */}
          <li>
            <div className="flex flex-row gap-2  mb-4">
              <img src="/logo192.png" alt="logo" className="w-10 h-10" />
              <div className="flex flex-col items-start">
                <p className="text-lg">次元通讯</p>
                <p className="text-xs">DCall.chat</p>
              </div>
            </div>
          </li>
          <Link to="/character/create/0" className="btn btn-accent btn-sm my-1">+ 创建角色</Link>
          <li> <Link to="/"><i className="fa fa-house w-4" />主页</Link></li>
          <li> <Link to="/share"><i className="fa fa-user-group w-4" />社区</Link></li>
          <li> <Link to="/chat"><i className="fa fa-comment-dots w-4" />聊天</Link></li>
          <li> <Link to="/me"><i className="fa fa-user w-4" />我的</Link></li>
        </ul>
      </div>
  </div>
  );
}

function LoginAndRegisterPage() {

  const handleRedirectToLogin = () => {
    setIsRegister(false)
  }
  const handleRedirectToRegister = () => {
    setIsRegister(true)
  }

  const [isRegister, setIsRegister] = useState(false)
  if (isRegister) return <RegisterPage handleRedirectToLogin={handleRedirectToLogin} />
  return <LoginPage handleRedirectToRegister={handleRedirectToRegister} />
}

const ProtectedRoute = () => {
  const { user, loading } = useUser();
  const unauthed = user === null && !loading;

  if (unauthed) {
    // If user not logged in, redirect to login page
    return <Navigate to="/login" replace />;
  }

  return <Outlet />; // Render children routes if authenticated
};

function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppContainer />}>
        <Route element={<NavbarContainer />}>
          <Route path="/" element={<HomePage />} />
          <Route path="share" element={<ContentListPage />} />
          <Route path="character" element={<CharacterListPage />} />
          <Route path="share" element={<ContentListPage />} />
          <Route element={<ProtectedRoute />}>
            <Route path="me" element={<ProfilePage />} />
            <Route path="chat" element={<CharacterChats />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="me/coin" element={<CoinPage />} />
          <Route path="payment" element={<PaymentPage />} />
          <Route path="withdraw" element={<WithdrawPage />} />
          <Route path="c/:charID" element={<ChatPage />} />
          <Route path="c/:charID/bg" element={<ChatBackgroundSelection />} />
          <Route path="gc/:groupID" element={<GroupChatPage />} />
          <Route path="groupchat/create/:groupID" element={<GroupChatCreatePage />} />
          <Route path="character/create/:charID" element={<CharacterCreator />} />
          <Route path="character/history/:charID" element={<ChatHistoryPage />} />
          <Route path="character/share/:charID" element={<ChatSharePage />} />
          <Route path="character/share/:charID" element={<ChatSharePage />} />
          <Route path="character/comment/:charID" element={<CharacterComments />} />
          <Route path="character/setting/:charID" element={<CharacterSettingPage />} />
          <Route path="writing/characters" element={<WriterCharactersPage />} />
          <Route path="writing/characters/:characterID" element={<WriterPage />} />
          <Route path="broadcasts/create" element={<BroadcastCreatePage />} />
          <Route path="admin/dashboard" element={<DashboardPage />} />
          <Route path="content/create" element={<CreateContentPage />} />
          <Route path="content/it/create" element={<CreateImageTextContentPage />} />
          <Route path="content/:content_id/mod" element={<ContentModPage />} />
          <Route path="contentwizard" element={<ContentWizard />} />
          <Route path="notification" element={<NotificationPage />} />
        </Route>
        <Route path="broadcasts/topics/:topic_id" element={<BroadcastTopicPage />}/>
        <Route path="content/:content_id" element={<ContentPage />} />
        <Route path="content/it/:content_id" element={<TextImageContentPage />} />
        <Route path="character/:charID" element={<CharacterPage />} />
        <Route path="setting" element={<SettingPage />} />
        <Route path="login" element={<LoginAndRegisterPage />} />
        <Route path="search" element={<SearchPage />} />
        <Route path="profile/:user_id" element={<PublicProfilePage />} />
        <Route path="user/email/verify" element={<EmailVerificationPage />} />
        <Route path="user/password/forget" element={<ForgetPasswordPage />} />
        <Route path="user/password/reset" element={<ResetPasswordPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    )
  )

  return (
    <UserProvider>
      <CharacterProvider>
        <RouterProvider router={router} />
      </CharacterProvider>
    </UserProvider>
  );
}

export default App;
